import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {NgBusyModule} from 'ng-busy';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

import {FtAuthModule, FtCoreModule, MaterialModule} from '@ft/core';
import {FtVisualizerModule} from '@ft/file-manager';

import {FtpViewerModule} from '../viewer/viewer.module';

import {ExplorerService} from './services/explorer.service';
import {ExplorerParentComponent} from './components/explorer-parent/explorer-parent.component';
import {ExplorerDrawerComponent} from './components/explorer-drawer/explorer-drawer.component';
import {StudySeriesComponent} from './components/study-series/study-series.component';
import {SeriesInstancesComponent} from './components/series-instances/series-instances.component';
import {StudiesComponent} from './components/studies/studies.component';
import {InstanceDetailsDialog} from './dialogs/instance-details/instance-details.dialog';
import {StudyUpdateDialog} from './dialogs/study-update/study-update.dialog';
import {RobotSelectionDialog} from './dialogs/robot-selection/robot-selection.dialog';

@NgModule({
    providers: [ExplorerService],
    imports: [
        CommonModule,
        FormsModule,
        NgBusyModule,
        RouterModule,
        TranslateModule.forChild(),
        FtCoreModule,
        FtAuthModule,
        FtVisualizerModule,
        MaterialModule,

        FtpViewerModule
    ],
    declarations: [
        ExplorerParentComponent,
        ExplorerDrawerComponent,

        StudiesComponent,
        StudySeriesComponent,
        SeriesInstancesComponent,
        InstanceDetailsDialog,
        StudyUpdateDialog,
        RobotSelectionDialog
    ]
})
export class FtpExplorerModule {
}
