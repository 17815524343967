import {Moment} from 'moment';
import {SelectConfig} from '@ft/core';
import {of} from 'rxjs';

const PATIENT_GENDERS: any[] = [
    {value: 'M', label: 'explorer.genders.male'}, {value: 'F', label: 'explorer.genders.female'}
];

export const PATIENT_GENDER_SELECT_CONFIG: SelectConfig = {
    key: 'value', compareKey: 'value',
    valueKey: 'value', observable: of(PATIENT_GENDERS)
};

export interface PatientModel {
    ID: string;
    PatientID: string;
    PatientName: string;
    PatientSex: string;
    PatientBirthDate: string;
    PatientStudies: number;

    MediaName: string;
}

export interface StudyModel {
    ID: string;
    AccessionNumber: string;
    InstitutionName: string;
    PatientBirthDate: string | Moment;
    PatientID: string;
    PatientName: string;
    PatientSex: string;
    ReferringPhysicianName: string;
    StudyDate: string | Moment;
    StudyDescription: string;
    StudyID: string;
    StudyInstanceUID: string;
    StudySeries: number;
    StudyTime: string;

    MediaName: string;
    StudyDateTime: number;
}

export interface SeriesModel {
    ID: string;
    Manufacturer: string;
    Modality: string;
    OperatorsName: string;
    ParentStudy: string;
    SeriesDate: string;
    SeriesInstanceUID: string;
    SeriesDescription: string;
    SeriesInstances: number;
    SeriesNumber: number;
    SeriesTime: string;
    StationName: string;
    Status: string;
}


export interface InstanceModel {
    ID: string;
    FileSize: number;
    ImageComments: string;
    InstanceNumber: string;
    NumberOfFrames: string;
    SOPClassUID: string;
    SOPInstanceUID: string;
    ParentSeries: string;
    IndexInSeries: string;
}

export interface DateAction {
    label: string;
    tooltip: string;
    meta: object | boolean;
}

export interface StudyQuery {
    PatientID?: string;
    PatientSex?: string;
    PatientName?: string;
    AccessionNumber?: string;
    StudyDescription?: string;
    PatientBirthDate?: Moment;
    ReferringPhysicianName?: string;

    ModalitiesInStudy?: string[];

    RangeAction?: DateAction;
    StudyDateEnd?: Moment;
    StudyDateStart?: Moment;
}

export const MODALITIES: string[] = [
    'CR', 'CT', 'DX', 'ECG', 'ES', 'MR', 'US', null
];

export const DATE_ACTIONS: DateAction[] = [
    {label: 'explorer.date_actions.one_day_short', tooltip: 'explorer.date_actions.one_day_long', meta: {days: 0}},
    {
        label: 'explorer.date_actions.three_days_short',
        tooltip: 'explorer.date_actions.three_days_long',
        meta: {days: -3}
    },
    {
        label: 'explorer.date_actions.seven_days_short',
        tooltip: 'explorer.date_actions.seven_days_long',
        meta: {days: -7}
    },
    {
        label: 'explorer.date_actions.fourteen_days_short',
        tooltip: 'explorer.date_actions.fourteen_days_long',
        meta: {days: -14}
    },
    {
        label: 'explorer.date_actions.thirty_days_short',
        tooltip: 'explorer.date_actions.thirty_days_long',
        meta: {days: -30}
    },
    {label: 'explorer.date_actions.any_short', tooltip: 'explorer.date_actions.any_long', meta: false},
];

export interface ExplorerConfig {
    availableModalities: any[];
    selectedModalities: any[];
    defaultDuration: any;
}
