import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {DateAdapter} from '@angular/material/core';

import {assign, chain, concat, get, isEmpty, map as loMap, pick} from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';

import {forkJoin, Observable, of, Subject, throwError} from 'rxjs';
import {delay, map, mergeMap, tap} from 'rxjs/operators';


import {GeneralConfigsService, GeneralPurposeService, SelectConfig, SMALL_DIALOG} from '@ft/core';
import {FtFileManagerSharedService} from '@ft/file-manager';

import {ExplorerConfig, InstanceModel, PatientModel, SeriesModel, StudyModel} from '../models/models';
import {PrintContainerComponent} from '../../viewer/components/print-container/print-container.component';
import {DCM_PRINT_DIALOG_DATA} from '../../viewer/services/print.service';
import {STUDY_QUERY_STORED} from '../../settings/models/consts';
import {RobotSelectionDialog} from '../dialogs/robot-selection/robot-selection.dialog';


@Injectable()
export class ExplorerService {
    public explorerConfig: ExplorerConfig;

    private _baseStudiesQuery = {Limit: 100, Expand: true, Level: 'Study'};

    constructor(
        private _router: Router,
        private _http: HttpClient,
        private _dialog: MatDialog,
        private _adapter: DateAdapter<any>,
        private _activatedRoute: ActivatedRoute,
        private _generalConfig: GeneralConfigsService,
        private _generalPurpose: GeneralPurposeService,
        private _previewDialog: FtFileManagerSharedService,
    ) {
    }

    // patient related
    public getPatient(id): Observable<PatientModel[]> {
        return forkJoin([this._http.get(`/dcm/patients/${id}`), this._http.get(`/dcm/patients/${id}/protected`)])
            .pipe(
                map((data) => assign(this._patientItemMap(data[0]), {Protected: data[1] === 1}))
            );
    }

    public protectPatient(patient) {
        const url = `/dcm/patients/${patient.ID}/protected`;
        const data = patient.Protected ? '0' : '1';

        return this._http.put(url, data)
            .pipe(
                map(() => new Object({Protected: data === '1'}))
            );
    }

    public downloadPatientMedia(patient: PatientModel): Observable<any> {
        return this._downloadInstance(patient, 'patients');
    }

    // studies related
    public getStudies(Query): Observable<StudyModel[]> {
        return this._http.post('/dcm/tools/find', Object.assign(this._baseStudiesQuery, {Query}))
            .pipe(
                map(data => this._studiesMap(data) as any)
            );
    }

    public getStudy(uid) {
        return this._http.get(`/dcm/studies/${uid}`)
            .pipe(
                map((data) => this._studyItemMap(data))
            );
    }

    public studySeries(uid): Observable<SeriesModel[]> {
        return this._http.get(`/dcm/studies/${uid}/series`)
            .pipe(
                map((data) => this._seriesMap(data) as any)
            );
    }

    public updateStudyTags(study: StudyModel, Replace: any) {
        return this._http.post(`/dcm/studies/${study.ID}/modify`, {Replace, KeepPrivateTags: true})
            .pipe(
                mergeMap(() => this._deleteItemCall(study, 'studies'))
            );
    }

    // series related
    public seriesInstances(uid): Observable<InstanceModel[]> {
        return forkJoin([
            this._http.get(`/dcm/series/${uid}/instances`),
            this._http.get(`/dcm/series/${uid}/instances-tags?simplify`)
        ]).pipe(
            map((data) => this._instancesMap(data))
        );
    }

    public getSeries(uid) {
        return this._http.get(`/dcm/series/${uid}`)
            .pipe(
                map((data) => this._seriesItemMap(data))
            );
    }

    // instance tags
    public instanceTags(uid) {
        const url = `/dcm/instances/${uid}/tags`;
        return this._http.get(url)
            .pipe(
                map((data) => this._tagsMap(data))
            );
    }

    public downloadMedia(study: StudyModel): Observable<any> {
        return this._downloadInstance(study, 'studies');
    }

    public anonymize(item, type, label, value?) {
        return this._generalPurpose.openConfirmDialog(label, value)
            .pipe(
                mergeMap(result => result ? this._anonymizeItem(item, type) : of(false))
            );
    }

    public deleteItem(item, type, label, value?) {
        return this._generalPurpose.openConfirmDialog(label, value)
            .pipe(
                mergeMap(result => result ? this._deleteItemCall(item, type) : of(false))
            );
    }

    // burner related
    public sendToRobot(item: StudyModel) {
        return this._generalPurpose.getByHttp('/api/burner-robot/burner-config/')
            .pipe(
                mergeMap(result => result.length === 0 ? of(result[0]) : this._openRobotSelection(result, item)),
                mergeMap(result => result ? this._sendToSpecificRobot(item, result) : of(false))
            );
    }

    // print related stuff
    public printStudy(study: StudyModel) {
        this._previewDialog.open(PrintContainerComponent, DCM_PRINT_DIALOG_DATA, {study: study.ID});
    }

    // explorer search related
    public loadUiConfig(): Observable<boolean> {
        return this._generalConfig.getOwnerConfig('explorer_config')
            .pipe(
                tap(config => this.explorerConfig = config || {availableModalities: [], selectedModalities: [], defaultDuration: null}),
                map(() => true)
            );
    }

    // tslint:disable-next-line:variable-name
    public saveUiConfig(explorer_config: ExplorerConfig): Observable<boolean> {
        return this._generalConfig.setOwnerConfig({explorer_config})
            .pipe(
                tap(() => {
                    this.explorerConfig = explorer_config;
                    localStorage.removeItem(STUDY_QUERY_STORED);
                })
            );
    }

    private _openRobotSelection(robots, study: StudyModel) {
        const selectConfig: SelectConfig = {key: 'title', autoSelect: true, observable: of(robots).pipe(delay(10))};

        const dialogRef = this._dialog.open(RobotSelectionDialog, assign(SMALL_DIALOG, {
            autoFocus: false,
            data: {selectConfig, study}
        }));

        return dialogRef.afterClosed()
            .pipe(
                mergeMap(value => value ? of(value) : throwError(value))
            );
    }

    private _sendToSpecificRobot(study, data): Observable<any> {
        return this._http.post(`/api/burner-robot/burner-config/generate-job/`, {
            study: study.ID, robot: data.robot.id,
            series: loMap(data.series, 'ID')
        });
    }

    // general private api
    private _anonymizeItem(item, type) {
        const url = `/dcm/${type}/${item.ID}/anonymize`;
        return this._http.post(url, {});
    }

    private _deleteItemCall(item, type) {
        const url = `/dcm/${type}/${item.ID}`;
        return this._http.delete(url)
            .pipe(
                map(() => true)
            );
    }

    private _studiesMap(items) {
        return chain(items as any)
            .map(item => this._studyItemMap(item))
            .orderBy('StudyDateTime', 'desc')
            .value();
    }

    private _patientItemMap(item) {
        const itemSex = get(item, 'MainDicomTags.PatientSex');
        const MediaName = `${get(item, 'MainDicomTags.PatientName')}.zip`;

        return chain(item)
            .get('MainDicomTags')
            .assign({ID: item.ID, PatientStudies: item.Studies.length, MediaName})
            .assign(itemSex === 'M' ? {PatientSexIcon: 'mdi-human-male'} : {})
            .assign(itemSex === 'F' ? {PatientSexIcon: 'mdi-human-female'} : {})
            .value();
    }

    private _studyItemMap(item) {
        const itemSex = get(item, 'PatientMainDicomTags.PatientSex');
        const studyDate = chain(item).get('MainDicomTags.StudyDate').thru((s) => isEmpty(s) ? 'Date' : s).value();
        const studyDescription = chain(item).get('MainDicomTags.StudyDescription').thru((s) => isEmpty(s) ? 'Description' : s).value();
        const MediaName = `${get(item, 'PatientMainDicomTags.PatientName')} - ${studyDescription} - ${studyDate}.zip`;
        return chain(item)
            .get('MainDicomTags')
            .assign(
                {
                    ID: item.ID,
                    StudySeries: item.Series.length,
                    ParentPatient: item.ParentPatient, MediaName
                },
                itemSex === 'M' ? {PatientSexIcon: 'mdi-human-male'} : {},
                itemSex === 'F' ? {PatientSexIcon: 'mdi-human-female'} : {},
                get(item, 'PatientMainDicomTags')
            )
            .mapValues((elem, key) => {
                if (key === 'StudyDate' && elem) {
                    return elem.split('.').join('');
                } else {
                    return elem;
                }
            })
            .thru((elem: any) => {
                const datetime = this._adapter.parse(`${elem.StudyDate}T${elem.StudyTime}`, 'YYYYMMDDTHHmm');
                return assign(elem, {StudyDateTime: datetime.isValid() ? datetime.valueOf() : -1});
            })
            .value();
    }

    private _seriesMap(items) {
        return chain(items as any)
            .map((item) => this._seriesItemMap(item))
            .orderBy('SeriesNumber')
            .value();
    }

    private _seriesItemMap(item) {
        const SeriesNumber = chain(item).get('MainDicomTags.SeriesNumber').parseInt().value();

        return chain(item)
            .get('MainDicomTags')
            .assign(
                pick(item, ['ID', 'ParentStudy', 'Status']),
                {SeriesInstances: item.Instances.length, SeriesNumber}
            )
            .mapValues((elem, key) => {
                if (key === 'SeriesDate' && elem) {
                    return elem.split('.').join('');
                } else {
                    return elem;
                }
            })
            .value();
    }

    private _instancesMap(items) {
        return chain(items[0] as any)
            .map(item => this._instanceItemMap(item, items[1]))
            .sortBy(item => parseInt(item.InstanceNumber, 0))
            .value();
    }

    private _instanceItemMap(item, tags) {
        return chain(item)
            .get('MainDicomTags')
            .assign(
                pick(item, ['ID', 'ParentSeries', 'IndexInSeries', 'FileSize']), {SOPClassUID: get(tags, `${item.ID}.SOPClassUID`)}
            )
            .value();
    }

    private _tagsMap(tags) {
        return chain(tags)
            .reduce((accumulator, item, key) => concat(accumulator, assign(item, {Tag: key})), [])
            .filter((item) => item.Type !== 'Sequence')
            .value();
    }


    // download related
    private _downloadInstance(instance: StudyModel | PatientModel, resource) {
        const subject = new Subject();
        this._generalPurpose.openProgressDialog(subject);

        return this._generalPurpose.getByEvent(
            'explorer.download_media', {pk: instance.ID, resource}
        ).pipe(
            tap(() => subject.complete()),
            map(path => `/download/settings/explorer/${btoa(path)}/download-media/`),
            mergeMap(url => this._generalPurpose.download(url, instance.MediaName))
        );
    }
}
