import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {chain, find, get, times, without} from 'lodash';
import {trackByFn} from '@ft/core';
import * as dragula from 'dragula';

import {ViewerService} from '../../services/viewer.service';
import {SeriesItem} from '../../classes/series-item';
import {ViewportConf} from '../../models/viewport-conf';


@Component({
    selector: 'ftp-viewer-container',
    templateUrl: './viewer-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        './viewer-container.component.scss'
    ]
})
export class ViewerContainerComponent implements AfterViewInit, OnInit, OnDestroy {
    public isOpen = false;
    public series: SeriesItem[];
    public loader: Subscription;

    public scaleOverlay = true;
    public activeToolName = '';
    public referenceLines = true;
    public viewports: ViewportConf[] = [];
    public trackByFn: any = trackByFn('id');
    public activeViewport: ViewportConf = {id: '-1', style: {}};

    public drake = dragula({
        copy: true,
        isContainer: el => el.classList.contains('viewport-wrapper'),
        invalid: el => el.classList.contains('viewport') || el.classList.contains('overlay'),
        moves: el => el.classList.contains('series-item') && el.classList.contains('series-item-loaded'),
    });

    // drake elements
    @ViewChild('seriesRef', {read: ElementRef, static: true}) seriesRef: ElementRef;
    @ViewChild('viewportsRef', {read: ElementRef, static: true}) viewportsRef: ElementRef;

    constructor(private _viewerService: ViewerService, protected _cdf: ChangeDetectorRef) {
    }

    public ngAfterViewInit(): void {
        this.drake.containers = [this.seriesRef.nativeElement, this.viewportsRef.nativeElement];

        this.drake.on('shadow', el => el.remove());
        this.drake.on('drop', (el, container) => container && container.id ? this.setSeries(container.id, el.id) : null);
    }

    public ngOnInit(): void {
        this.loader = this._viewerService.handleStudy()
            .subscribe(series => this.series = series);
    }

    public ngOnDestroy(): void {
        if (this.drake) this.drake.destroy();
    }

    public handleLayout(layout) {
        const {x, y} = this.getViewportsCount(layout);
        const style = this.getViewportStyle(x, y);

        this.viewports = times(x * y, i => this.generateViewports(this.viewports, style, i));
        Promise.resolve(null).then(() => this.activeViewport = this.viewports[0]);
    }

    public getViewportStyle(x: number, y: number) {
        return {height: `calc(${100 / x}% - 10px)`, width: `calc(${100 / y}% - 8px)`};
    }

    public handleErrorSeries(series) {
        this.series = without(this.series, series);
    }

    public handleCompleted(series) {
        if (this.viewports.length > 1 || this.viewports[0].series) {
            return;
        } else {
            this.viewports[0].series = series;
        }
    }

    public get activeSeries() {
        return chain(this.viewports).filter('series').map(item => item.series.id).value();
    }

    // general api
    public setSeries(id: string, series: string): void {
        const viewportConf: ViewportConf = find(this.viewports, {id});
        const seriesItem: SeriesItem = find(this.series, {id: series});

        if (viewportConf && seriesItem) {
            viewportConf.series = seriesItem;
            this.activeViewport = viewportConf;
        }

        this._cdf.markForCheck();
    }

    public getViewportsCount(layout) {
        const layoutDim = layout.split(/x/);

        const x = parseInt(layoutDim[1], null);
        const y = parseInt(layoutDim[0], null);

        return {x, y};
    }

    public generateViewports(viewports, style, i): ViewportConf {
        const activeToolName = get(viewports as any, `${i}.activeToolName`, null) || 'Wwwc';
        const series = get(viewports as any, `${i}.series`, null) || get(this.series as any, `${i}`, null);

        return {id: `ft-viewer-container-${i}`, style, activeToolName, series} as ViewportConf;
    }
}
