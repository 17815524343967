export const lang = {
    navbar: {
        patients: 'Patients',
        studies: 'Studies',
        series: 'Series',
        instances: 'Instances',
    },

    search: {
        start_date: 'Start',
        end_date: 'End',
        refresh_studies: 'Refresh studies'
    },

    // table related
    study: {
        patient_id: 'ID',
        patient_name: 'Patient name',
        patient_birth_date: 'Birth Date',
        patient_sex: 'Sex',
        accession_number: 'Accession number',
        study_description: 'Description',
        study_id: 'ID',
        study_series: 'Series',
        study_date: 'Date',
        study_time: 'To',
        referring_physician_name: 'Referring physician',
        institution_name: 'Clinic',
    },

    series: {
        manufacturer: 'Manufacturer',
        operators_name: 'Operator',
        series_description: 'Series description',
        station_name: 'Station',
        series_number: 'N° of series',
        modality: 'Modality',
        status: 'Status',
        series_instances: 'Instances',
        series_date: 'Date',
        series_time: 'To',
    },

    instance: {
        instance_number: 'Instance Number',
        number_of_frames: 'Number of frames',
        image_comments: 'Comments',
        file_size: 'File size',
        index_in_series: 'Series index',
        sop_instance_uid: 'SOPInstanceUID',

        tags: 'Tags',
        preview: 'Preview',
    },


    // TODO enhance translations
    cancel: 'Cancel',

    genders: {
        male: 'Male',
        female: 'Female',
    },

    actions: {
        print: 'Print',
        anonymize: 'Anonymize',
        download_zip: 'Download zip',
        more_actions: 'More actions',
        download_patient_studies: 'Download studies patient',
        visualize_study: 'Visualize',
        visualize_series: 'Visualize',
        delete: 'Delete',
        protect_patient: 'Protect the patient',
    },

    confirm: {
        anonymize_patient_confirm: 'Are you sure to anonymize the patient "{{value}}"',
        anonymize_study_confirm: 'Are you sure to anonymize the study "{{value}}"',
        anonymize_series_confirm: 'Are you sure to anonymize series "{{value}}"',
        delete_patient_confirm: 'would you like to delete the patient "{{value}}"',
        delete_study_confirm: 'Are you sure to delete the study "{{value}}"',
        delete_series_confirm: 'Are you sure to delete series "{{value}}"',
        delete_instance_confirm: 'Are you sure to delete the instance "{{value}}"',
    },

    side_nave: {
      copy_to_clipboard: 'Tag copied to clipboard with success !!'
    },

    series_update: {
        tooltip: 'Edit',
        dialog_label: 'Edit the study',
        study_date: 'Date of the study',
        study_time: 'Time of the study',
    },

    media: {
        send_to_robot: 'Send to robot',
        send_to_robot_fail: 'No robot is configured !!',
        download_media: 'Download',
        robot_selection_label: 'Please select a robot',
        robot_selection_placeholder: 'Name of the robot',
        series_selection_placeholder: 'Series to be burned',
        send_to_robot_success: 'The study has been sent to the robot successfully',
    },

    modality_action: {
        all: 'All'
    },

    date_actions: {
        one_day_short: '1d',
        one_day_long: 'One day',
        three_days_short: '3d',
        three_days_long: 'Three days',
        seven_days_short: '7d',
        seven_days_long: 'One week',
        fourteen_days_short: '14d',
        fourteen_days_long: 'Two weeks',
        thirty_days_short: '30d',
        thirty_days_long: 'One month',
        any_short: 'All',
        any_long: 'Without a restriction date',
    }
};
