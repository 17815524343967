import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgBusyModule} from 'ng-busy';
import {ngfModule} from 'angular-file';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FtAuthModule, FtCoreModule, MaterialModule} from '@ft/core';


import {MainSettingsComponent} from './components/main-settings/main-settings.component';
import {PacsGeneralComponent} from './components/pacs-general/pacs-general.component';
import {ModalitySetupComponent} from './components/modality-setup/modality-setup.component';
import {SettingsService} from './services/settings.service';
import {IanNodesSetupComponent} from './components/ian-nodes-setup/ian-nodes-setup.component';
import {ModalityFormDialog} from './dialogs/modality-form/modality-form.dialog';
import {IanNodeFormDialog} from './dialogs/ian-node-form/ian-node-form.dialog';
import {ArchiveTaskSetupComponent} from './components/archive-task-setup/archive-task-setup.component';
import {ArchiveTaskFormDialog} from './dialogs/archive-task-form/archive-task-form.dialog';
import {ArchivesComponent} from './components/archives/archives.component';
import {ArchiveProgressDialog} from './dialogs/archives-progress/archive-progress.dialog';
import {DcmUploadComponent} from './components/dcm-upload/dcm-upload.component';
import {BurnerSetupComponent} from './components/burner-setup/burner-setup.component';
import {BurnerConfigFormDialog} from './dialogs/burner-config-form/burner-config-form.dialog';
import {PrintersSetupComponent} from './components/printers-setup/printers-setup.component';
import {PrinterFormDialog} from './dialogs/printer-form/printer-form.dialog';
import {PrintScpSetupComponent} from './components/print-scp-setup/print-scp-setup.component';
import {PrintScpRuleDialog} from './dialogs/print-scp-rule/print-scp-rule.dialog';
import {BurnerRuleDialog} from './dialogs/burner-rule/burner-rule.dialog';
import { DicomizerDialog } from './dialogs/dicomizer/dicomizer.dialog';
import {UploadHandlerService} from './services/upload-handler.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgBusyModule,
        RouterModule,
        TranslateModule.forChild(),
        FtCoreModule,
        FtAuthModule,
        MaterialModule,
        ngfModule
    ],
    declarations: [
        MainSettingsComponent,
        PacsGeneralComponent,
        ModalitySetupComponent,
        ModalityFormDialog,
        IanNodesSetupComponent,
        IanNodeFormDialog,
        ArchiveTaskSetupComponent,
        ArchiveTaskFormDialog,
        ArchiveProgressDialog,
        ArchivesComponent,
        DcmUploadComponent,

        BurnerSetupComponent,
        BurnerConfigFormDialog,

        PrintersSetupComponent,
        PrinterFormDialog,
        PrintScpSetupComponent,
        PrintScpRuleDialog,
        BurnerRuleDialog,
        DicomizerDialog
    ],
    providers: [
        SettingsService, UploadHandlerService
    ]
})
export class FtpSettingsModule {
}
