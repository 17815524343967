import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {of} from 'rxjs';
import {get} from 'lodash';
import {SelectConfig} from '@ft/core';
import {TranslateService} from '@ngx-translate/core';
import {SeriesItem} from '../../classes/series-item';
import {MANUAL_SELECTION, SERIES_IMAGE_SELECTION_METHODS, SeriesPrintRule} from '../../models/print-config';

@Component({
    selector: 'ftp-series-print',
    templateUrl: './series-print.dialog.html',
    styleUrls: [
        './series-print.dialog.scss'
    ]
})
export class SeriesPrintDialog implements OnInit {
    public data: SeriesPrintRule = {};
    public series: SeriesItem;
    public manualSelection = MANUAL_SELECTION;
    public selectConfig: SelectConfig = {
        key: 'value', compareKey: 'value', valueKey: 'value', observable: of(SERIES_IMAGE_SELECTION_METHODS),
        optionContent: item => this._translateService.instant(item.label),
    };

    constructor(
        private _translateService: TranslateService,
        private _dialogRef: MatDialogRef<SeriesPrintDialog>,
        @Inject(MAT_DIALOG_DATA) data: any,
    ) {
        this.series = get(data, 'series');

        this.data.count = 1;
        this.data.method = SERIES_IMAGE_SELECTION_METHODS[0].value;
    }

    public ngOnInit(): void {
        console.log(this.series);
    }

    public submit() {

    }
}
