export const lang = {
    navbar: {
        patients: 'Patients',
        studies: 'Études',
        series: 'Séries',
        instances: 'Instances',
    },

    search: {
        start_date: 'Debut',
        end_date: 'Fin',
        refresh_studies: 'Actualiser les études'
    },

    // table related
    study: {
        patient_id: 'ID',
        patient_name: 'Nom du patient',
        patient_sex: 'Sexe',
        patient_birth_date: 'Date de naissance',
        patient_studies: 'Études',
        accession_number: 'Numéro d\'accès',
        study_id: 'Identifiant d\'étude',
        study_description: 'Description',
        study_series: 'Series',
        study_date: 'Date',
        study_time: 'à',
        referring_physician_name: 'Médecin référant',
        institution_name: 'Centre',
    },

    series: {
        manufacturer: 'Fabricant',
        operators_name: 'Opérateurs',
        series_description: 'Description',
        station_name: 'Station',
        series_number: 'N° de série',
        modality: 'Modalité',
        status: 'Status',
        series_instances: 'Instances',
        series_date: 'Date',
        series_time: 'à',
    },

    instance: {
        instance_number: 'Numéro de l\'instance',
        number_of_frames: 'Nombre de cadres',
        image_comments: 'Commentaires',
        file_size: 'Taille du fichier',
        index_in_series: 'Index en série',
        sop_instance_uid: 'SOPInstanceUID',

        tags: 'Tag d\'instance',
        preview: 'Aperçu',
    },


    cancel: 'Annuler',

    genders: {
        male: 'Homme',
        female: 'Femme',
    },

    actions: {
        print: 'Imprimer',
        delete: 'Supprimer',
        anonymize: 'Anonymiser',
        visualize_study: 'Visualiser',
        visualize_series: 'Visualiser',
        more_actions: 'Plus d\'actions',
        protect_patient: 'Protéger le patient',
        download_patient_studies: 'Télécharger les études du patient',
    },

    confirm: {
        anonymize_patient_confirm: 'Voulez vous anonymiser le patient "{{value}}"',
        anonymize_study_confirm: 'Voulez vous anonymiser l\'étude "{{value}}"',
        anonymize_series_confirm: 'Voulez vous anonymiser la serie "{{value}}"',
        delete_patient_confirm: 'Voulez vous supprimé le patient "{{value}}"',
        delete_study_confirm: 'Voulez vous supprimé l\'étude "{{value}}"',
        delete_series_confirm: 'Voulez vous supprimé la serie "{{value}}"',
        delete_instance_confirm: 'Voulez vous supprimé l\'instance "{{value}}"',
    },

    side_nave: {
        copy_to_clipboard: 'Tag copié dans le presse-papiers avec succès !!'
    },

    series_update: {
        tooltip: 'Modifier',
        dialog_label: 'Modifier l\'étude',
        study_date: 'Date d\'étude',
        study_time: 'Heure d\'étude',
    },

    media: {
        send_to_robot: 'Envoyer au robot gravure',
        send_to_robot_fail: 'Aucun robot configuré !!',
        download_media: 'Téléchager',
        robot_selection_label: 'Veuillez sélectionner un robot',
        robot_selection_placeholder: 'Nom du Robot',
        series_selection_placeholder: 'Series à gravée',
        send_to_robot_success: 'Envoi d\'étude au robot gravure a été avec succès',
    },

    modality_action: {
        all: 'Tous'
    },

    date_actions: {
        one_day_short: '1j',
        one_day_long: 'Un jour',
        three_days_short: '3j',
        three_days_long: 'Trois jours',
        seven_days_short: '7j',
        seven_days_long: 'Une semain',
        fourteen_days_short: '14j',
        fourteen_days_long: 'Deux semains',
        thirty_days_short: '30j',
        thirty_days_long: 'Un mois',
        any_short: 'Tous',
        any_long: 'Sans date restriction',
    }
};
