import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';

import {assign, map as loMap} from 'lodash';
import {Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';

import {FtWsService, GeneralConfigsService, GeneralPurposeService} from '@ft/core';
import {BURNER_REFRESH_TOPIC, PRINT_REFRESH_TOPIC} from '../models/consts';

@Injectable()
export class SettingsService {
    private readonly _updateSuccessLabel: string = '';

    constructor(
        private _ws: FtWsService,
        private _http: HttpClient,
        private _snackBar: MatSnackBar,
        private _generalConfig: GeneralConfigsService,
        private _generalPurpose: GeneralPurposeService,
        private _translateService: TranslateService) {
        this._updateSuccessLabel = this._translateService.instant('settings.update_success');
    }

    public getPacsGeneral() {
        return this._generalConfig.getAppConfig('pacs_general_settings', true)
            .pipe(
                map((data) => data || {})
            );
    }

    public setPacsGeneral(config) {
        return this._generalConfig.setAppConfig({pacs_general_settings: config})
            .pipe(
                mergeMap((data) => this.refreshConfig(data))
            );
    }

    public getModalities() {
        const url = '/api/settings/dicom-modality/';
        return this._generalPurpose.getByHttp(url);
    }

    public saveModality(modality) {
        return this._generalPurpose.postHttp('/api/settings/dicom-modality/', modality)
            .pipe(
                mergeMap(data => this.refreshConfig(data))
            );
    }

    public removeModality(modality) {
        return this._generalPurpose.openConfirmDialog('settings.modality_remove_confirm', modality)
            .pipe(
                mergeMap(result => result ? this.removeModalityItem(modality) as any : of(false))
            );
    }

    public refreshConfig(data) {
        return this._ws.call('settings.update_config')
            .pipe(
                mergeMap(() => this.reloadInstance()),
                map(() => data), tap(() => this._showUpdateSuccess())
            );
    }

    public reloadInstance() {
        return this._ws.call('settings.reset_instances')
            .pipe(
                map(() => true)
            );
    }

    // hl7 related
    public getHl7Config() {
        return this._generalConfig.getAppConfig('hl7_config', true);
    }

    public setHl7Config(config) {
        return this._generalConfig.setAppConfig({hl7_config: config})
            .pipe(
                mergeMap(() => this._ws.call('settings.reset_process')),
                map(() => true)
            );
    }

    // Ian Nodes
    public getNodes() {
        const url = '/api/settings/ian-node/';
        return this._generalPurpose.getByHttp(url);
    }

    public saveNode(node) {
        return this._generalPurpose.postHttp('/api/settings/ian-node/', node)
            .pipe(
                mergeMap((data) => this.refreshConfig(data))
            );
    }

    public removeNode(node) {
        return this._generalPurpose.openConfirmDialog('settings.ian_nodes.remove_confirm', node)
            .pipe(
                mergeMap(result => result ? this.removeNodeItem(node) as any : of(false))
            );
    }

    // printer
    public getPrinters() {
        const url = '/api/printing/printer/';
        return this._generalPurpose.getByHttp(url);
    }

    public savePrinter(printer) {
        return this._generalPurpose.postHttp('/api/printing/printer/', printer);
    }

    public removePrinter(printer) {
        return this._generalPurpose.openConfirmDialog('settings.printer.remove_confirm', printer)
            .pipe(
                mergeMap(result => result ? this._removePrinterItem(printer) as any : of(false))
            );
    }

    // print scp
    public getPrintScpConfig() {
        return this._generalConfig.getAppConfig('print_scp_settings', true)
            .pipe(
                map((data) => data || {})
            );
    }

    public setPrintScpConfig(config) {
        return this._generalConfig.setAppConfig({print_scp_settings: config})
            .pipe(
                mergeMap(() => config.is_active ? this.refreshInstance(PRINT_REFRESH_TOPIC) : of(true)),
                tap(() => this._showUpdateSuccess())
            );
    }

    public refreshInstance(topic) {
        return this._ws.call(topic)
            .pipe(
                map(() => true)
            );
    }

    // archive Task
    public getTasks() {
        const url = '/api/settings/archive-task/';
        return this._generalPurpose.getByHttp(url);
    }

    public saveTask(task) {
        return this._generalPurpose.postHttp('/api/settings/archive-task/', task);
    }

    public removeTask(task) {
        return this._generalPurpose.openConfirmDialog('settings.archive.task_remove_confirm', task)
            .pipe(
                mergeMap(result => result ? this._removeTaskItem(task) as any : of(false))
            );
    }

    public getArchivedStudies(path, query?: any) {
        return this._ws.call('archive.archive_path_indexing', {path, query});
    }

    public unArchiveStudies(studies): Observable<any> {
        return this._ws.call('archive.un_archive_studies', {studies});
    }

    public removeArchivedStudies(studies) {
        return this._ws.call('archive.remove_archived_studies', {studies});
    }

    // Burner
    public getBurnersConfig() {
        return this._generalPurpose.getByHttp('/api/burner-robot/burner-config/');
    }

    public getBurnersJobs() {
        return this._generalPurpose.getByHttp('/api/burner-robot/burner-config/current-jobs/')
            .pipe(
                map(items => loMap(items, i => {
                    return assign(i, {statusTranslated: el => this._translateService.instant(`settings.burner.${el.status}`)});
                }))
            );
    }

    public saveBurnerConfig(burnerConfig) {
        return this._generalPurpose.postHttp('/api/burner-robot/burner-config/', burnerConfig);
    }

    public removeBurnerConfig(burnerConfig) {
        return this._generalPurpose.openConfirmDialog('settings.burner.remove_confirm', burnerConfig)
            .pipe(
                mergeMap(result => result ? this._removeBurnerConfigItem(burnerConfig) as any : of(false))
            );
    }

    // burner scp config
    public getBurnerScpConfig() {
        return this._generalConfig.getAppConfig('burner_scp_settings', true)
            .pipe(
                map((data) => data || {})
            );
    }

    public setBurnerScpConfig(config) {
        return this._generalConfig.setAppConfig({burner_scp_settings: config})
            .pipe(
                mergeMap(() => config.is_active ? this.refreshInstance(BURNER_REFRESH_TOPIC) : of(true)),
                tap(() => this._showUpdateSuccess())
            );
    }

    // modalities
    private removeModalityItem(modality) {
        const url = `/api/settings/dicom-modality/`;
        return this._generalPurpose.deleteHttp(url, modality)
            .pipe(
                mergeMap(() => this.refreshConfig(true))
            );
    }

    private removeNodeItem(node) {
        const url = `/api/settings/ian-node/`;
        return this._generalPurpose.deleteHttp(url, node)
            .pipe(
                map(() => true)
            );
    }

    private _removePrinterItem(printer) {
        const url = `/api/printing/printer/`;
        return this._generalPurpose.deleteHttp(url, printer)
            .pipe(
                map(() => true)
            );
    }

    private _removeBurnerConfigItem(burnerConfig) {
        const url = `/api/burner-robot/burner-config/`;
        return this._generalPurpose.deleteHttp(url, burnerConfig)
            .pipe(
                map(() => true)
            );
    }

    // private api
    private _removeTaskItem(task) {
        const url = `/api/settings/archive-task/`;
        return this._generalPurpose.deleteHttp(url, task)
            .pipe(
                map(() => true)
            );
    }

    private _showUpdateSuccess() {
        this._snackBar.open(this._updateSuccessLabel, '', {
            duration: 2000,
            panelClass: 'ft-snackbar',
            verticalPosition: 'bottom',
            horizontalPosition: 'left'
        });
    }

}
