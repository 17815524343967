export const lang = {
    loading: 'Chargement... {{percentage}}%',
    please_select_series: 'Faites glisser une series ici pour voir les images.',

    arrow_annotation: {
        dialog_title: 'Titre du l\'annotation',
        dialog_placeholder: 'Annotation'
    },

    download_instance: {
        dialog_title: 'Téléchargement',
        dialog_placeholder: 'Titre'
    },

    tools: {
        stack_scroll: 'Pile de défilement',
        zoom: 'Zoom',
        contrast: 'Contraste',
        pan: 'Pan',
        magnify: 'Amplifier',
        length_measurement: 'Mesure de longueur',
        arrow_annotate: 'Flèche annoter',
        angle_measurement: 'Mesure d\'angle',
        elliptical_roi: 'Elliptique ROI',
        rectangle_roi: 'Rectangle ROI',
        pixel_probe: 'Sonde de pixel',
        freehand: 'Main levée',
        highlight: 'Highlight',
        crosshairs: 'Crosshairs',
        roi_window: 'Contraste ROI',
        eraser: 'Gomme',
        reset: 'Réinitialiser',
        stop: 'Stop',
        play: 'Play',
        fps: 'Images par seconde',
        invert: 'Inverser',
        rotate_left: 'Tourne à gauche',
        rotate_right: 'Tourner à droite',
        h_flip: 'Retournement horizontal',
        v_flip: 'Retournement vertical',
        download: 'Télécharger',

        layouts: 'Disposition',
        clear_tools: 'Supprimer les annotations',
        viewport_overlay: 'Superposition de texte',
        viewport_scale_overlay: 'Superposition d\'échelle',
        viewports_reference_lines: 'Lignes de référence',
        synchronize_contrast: 'Synchroniser le contraste',
        synchronize_pan_zoom: 'Synchronizer Pan/Zoom',
    },

    series: {
      loading: 'Chargement...'
    },

    print: {
        // buttons
        launch: 'Imprimer',
        cancel: 'Annuler',

        // series instances selection
        series_handle: 'Selection des images',
        image_count: 'Nombre d\'images',
        selection_method: 'Methode de selection',
        apply: 'Appliquer',
        append: 'Ajouter',
        incremental: 'Incremental',
        slices: 'Coupes',
        manual: 'Manual',

        // blocks
        printer_block: 'Imprimante',
        printer_options_block: 'Options d\'impression',
        film_options_block: 'Options de film',

        // options
        film_type: 'Type de film',
        priority: 'Priorité',
        destination: 'Destination (film)',
        copies: 'Copies',
        color_print: 'Imprimer en couleur',

        // film options
        paper_orientation: 'Orientation (film)',
        film_size: 'Taille de film',
        print_display_mode: 'Format d\'affichage',
        print_interpolation: 'Interpolation',
        smooth: 'Type de lissage',
        border: 'Bordure',
        emptyImageDensity: 'Remplissage',
        dpi: 'DPI',

        error_message: 'La tâche d\'impression a rencontré une erreur !!'
    }

};
