<mat-toolbar>
    <button mat-button class="menu-button" (click)="handleSideNave()" [class.active]="isOpen">
        <mat-icon fontSet="mdi" fontIcon="mdi-menu"></mat-icon>
    </button>

    <div class="logo">
        <img src="/assets/fire-view.png" alt="" class="large">
        <img src="/assets/fire-view-small.png" alt="" class="small">
    </div>

    <hr class="separator">

    <button mat-button [matTooltip]="'viewer.tools.stack_scroll' | translate" (click)="setActiveTool('StackScroll')" [class.active]="activeToolName === 'StackScroll'">
        <mat-icon fontSet="mdi" fontIcon="mdi-layers"></mat-icon>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.zoom' | translate" (click)="setActiveTool('Zoom')" [class.active]="activeToolName === 'Zoom'">
        <mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
    </button>

    <button mat-button (click)="setActiveTool('Wwwc')" [class.active]="activeToolName === 'Wwwc'">
        <mat-icon fontSet="mdi" fontIcon="mdi-contrast"></mat-icon>
    </button>

    <button mat-button [matTooltip]="'viewer.tools.roi_window' | translate" (click)="setActiveTool('WwwcRegion')" [class.active]="activeToolName === 'WwwcRegion'">
        <mat-icon fontSet="mdi" fontIcon="mdi-contrast-box"></mat-icon>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.pan' | translate" (click)="setActiveTool('Pan')" [class.active]="activeToolName === 'Pan'">
        <mat-icon fontSet="mdi" fontIcon="mdi-cursor-move"></mat-icon>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.magnify' | translate" (click)="setActiveTool('Magnify')" [class.active]="activeToolName === 'Magnify'">
        <mat-icon fontSet="mdi" fontIcon="mdi-checkbox-blank-circle"></mat-icon>
    </button>

    <hr class="separator">

    <button mat-button [matTooltip]="'viewer.tools.length_measurement' | translate" (click)="setActiveTool('Length')" [class.active]="activeToolName === 'Length'">
        <mat-icon fontSet="mdi" fontIcon="mdi-ruler"></mat-icon>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.arrow_annotate' | translate" (click)="setActiveTool('ArrowAnnotate')" [class.active]="activeToolName === 'ArrowAnnotate'">
        <mat-icon fontSet="mdi" fontIcon="mdi-link-plus"></mat-icon>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.angle_measurement' | translate" (click)="setActiveTool('Angle')" [class.active]="activeToolName === 'Angle'">
        <mat-icon fontSet="mdi" fontIcon="mdi-angle-right"></mat-icon>
    </button>

    <button mat-button [matTooltip]="'viewer.tools.elliptical_roi' | translate" (click)="setActiveTool('EllipticalRoi')" [class.active]="activeToolName === 'EllipticalRoi'">
        <mat-icon fontSet="mdi" fontIcon="mdi-checkbox-blank-circle-outline"></mat-icon>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.rectangle_roi' | translate" (click)="setActiveTool('RectangleRoi')" [class.active]="activeToolName === 'RectangleRoi'">
        <mat-icon fontSet="mdi" fontIcon="mdi-crop-square"></mat-icon>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.pixel_probe' | translate" (click)="setActiveTool('Bidirectional')" [class.active]="activeToolName === 'Bidirectional'">
        <mat-icon fontSet="mdi" fontIcon="mdi-google-circles-extended"></mat-icon>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.freehand' | translate" (click)="setActiveTool('DragProbe')" [class.active]="activeToolName === 'DragProbe'">
        <mat-icon fontSet="mdi" fontIcon="mdi-gesture"></mat-icon>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.eraser' | translate" (click)="setActiveTool('Eraser')" [class.active]="activeToolName === 'Eraser'">
        <mat-icon fontSet="mdi" fontIcon="mdi-eraser"></mat-icon>
    </button>

    <hr class="separator">

    <button mat-button [matTooltip]="'viewer.tools.crosshairs' | translate" (click)="setActiveTool('Crosshairs')" [class.active]="activeToolName === 'Crosshairs'">
        <mat-icon fontSet="mdi" fontIcon="mdi-crosshairs-gps"></mat-icon>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.viewport_scale_overlay' | translate" (click)="updateScaleOverlay()" [class.active]="scaleOverlay">
        <mat-icon fontSet="mdi" fontIcon="mdi-ruler-square" class="mdi-rotate-180"></mat-icon>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.viewports_reference_lines' | translate" (click)="updateReferenceLines()" [class.active]="referenceLines">
        <mat-icon fontSet="mdi" fontIcon="mdi-border-none"></mat-icon>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.synchronize_contrast' | translate" (click)="enableSynchronizer()" [class.active]="wwccSynchronizer">
        <mat-icon fontSet="mdi" fontIcon="mdi-vector-link"></mat-icon>
    </button>

    <hr class="separator">

    <button mat-button class="bright" [matTooltip]="'viewer.tools.reset' | translate" (click)="resetViewport()">
        <mat-icon fontSet="mdi" fontIcon="mdi-backup-restore"></mat-icon>
    </button>
    <button mat-button class="bright" [matTooltip]="(state?.playing ? 'viewer.tools.stop' : 'viewer.tools.play') | translate" (click)="setState('playing', !state.playing)">
        <mat-icon fontSet="mdi" [fontIcon]="state?.playing ? 'mdi-stop' : 'mdi-play'"></mat-icon>
    </button>

    <button mat-button class="bright" [matTooltip]="'viewer.tools.invert' | translate" (click)="setState('invert', !state.invert)" [class.active]="!state?.invert">
        <mat-icon fontSet="mdi" fontIcon="mdi-invert-colors"></mat-icon>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.rotate_left' | translate" (click)="setState('rotation', state.rotation - 90)">
        <mat-icon fontSet="mdi" fontIcon="mdi-rotate-left-variant"></mat-icon>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.rotate_right' | translate" (click)="setState('rotation', state.rotation + 90)">
        <mat-icon fontSet="mdi" fontIcon="mdi-rotate-right-variant"></mat-icon>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.h_flip' | translate" (click)="setState('hflip', !state.hflip)" [class.active]="state?.hflip">
        <mat-icon fontSet="mdi" fontIcon="mdi-swap-horizontal"></mat-icon>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.v_flip' | translate" (click)="setState('vflip', !state.vflip)" [class.active]="state?.vflip">
        <mat-icon fontSet="mdi" fontIcon="mdi-swap-vertical"></mat-icon>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.download' | translate" (click)="download()">
        <mat-icon fontSet="mdi" fontIcon="mdi-download"></mat-icon>
    </button>

    <hr class="separator">

    <button mat-button class="bright" [matTooltip]="'viewer.tools.layouts' | translate" [matMenuTriggerFor]="layoutsMenu">
        <mat-icon fontSet="mdi" fontIcon="mdi-grid"></mat-icon>
    </button>

    <button mat-button class="bright" [matTooltip]="'viewer.tools.fps' | translate" [matMenuTriggerFor]="fpsMenu">
        <mat-icon fontSet="mdi" fontIcon="mdi-animation-play"></mat-icon>
    </button>

</mat-toolbar>

<mat-menu #layoutsMenu="matMenu" yPosition="above" xPosition="before" class="layout-menu">
    <button *ngFor="let item  of layouts" mat-menu-item (click)="setLayout(item)" [class.active]="item === layout">
        {{item}}
    </button>
</mat-menu>

<mat-menu #fpsMenu="matMenu" yPosition="above" xPosition="before" class="fps-menu">
    <button *ngFor="let item  of fps" mat-menu-item (click)="setState('frameRate', item)" [ngClass]="{active: item == state?.frameRate}">
        {{item}}
    </button>
</mat-menu>
