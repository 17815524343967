import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {map, mergeMap, take, tap} from 'rxjs/operators';
import {chain, includes, get} from 'lodash';
import {forkJoin, Observable} from 'rxjs';

import {SeriesItem} from '../classes/series-item';


@Injectable()
export class ViewerService {
    public currentSeries: string;

    constructor(
        private _http: HttpClient,
        private _route: ActivatedRoute,
    ) {
    }

    public handleStudy(): Observable<any[]> {
        return this._route.queryParams
            .pipe(
                take(1),
                tap(params => this.currentSeries = params.series),
                mergeMap(params => this._getStudyObservable(params)),
                mergeMap(study => this.mapStudy(study))
            );
    }

    // exposed api
    public getStudyByID(id) {
        return this._http.get(`/dcm/studies/${id}`);
    }

    // study related private api
    private _getStudyObservable(params) {
        if (params.study) {
            return this.getStudyByID(params.study);
        } else {
            return this._getStudyByUID(params);
        }
    }

    private _getStudyByUID(params) {
        return this._http.post('/dcm/tools/find', {Level: 'Study', Expand: true, Query: {StudyInstanceUID: params.studyInstanceUID}})
            .pipe(
                map(data => data[0])
            );
    }

    public mapStudy(study): Observable<SeriesItem[]> {
        return chain(study)
            .get('Series')
            .map(uid => this._seriesSlices(study, uid))
            .thru(observables => {
                return forkJoin(observables).pipe(
                    map(items => chain(items).flatten()
                        .filter(item => !includes(['OT', 'SR'], item.modality))
                        .orderBy('seriesNumber')
                        .value()
                    )
                );
            })
            .value();
    }

    private _seriesSlices(study, uid) {
        return forkJoin([
            this._http.get(`/dcm/series/${uid}/instances`),
            this._http.get(`/dcm/series/${uid}/module?simplify`)
        ]).pipe(
            map(item => this._handleSeriesItem(study, uid, item)),
        );
    }

    private _handleSeriesItem(study, uid, data) {
        const tags = data[1];
        const instances = chain(data[0]).orderBy(
            item => chain(item).get('MainDicomTags.InstanceNumber').parseInt().value(), 'asc'
        ).value();

        const singleFrames = chain(instances)
            .filter(item => chain(item).get('MainDicomTags.NumberOfFrames', 0).toNumber().value() === 0)
            .thru(items => items.length > 0 ? new SeriesItem(study, uid, tags, items) : null)
            .value();

        const multiFrames =  chain(instances)
            .filter(item => chain(item).get('MainDicomTags.NumberOfFrames', 0).toNumber().value() > 0)
            .map(item => {
                const pk = get(item, 'MainDicomTags.SOPInstanceUID');
                const frameCount = chain(item).get(`MainDicomTags.NumberOfFrames`).toNumber().value();

                return new SeriesItem(study, uid, tags, pk, frameCount);
            }).value();

        return chain(singleFrames).concat(multiFrames).compact().value();

    }
}
