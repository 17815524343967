import {Component} from '@angular/core';
import {assign} from 'lodash';
import {PATIENT_GENDER_SELECT_CONFIG} from '../../../explorer/models/models';
import {Subscription} from 'rxjs';
import {UploadHandlerService} from '../../services/upload-handler.service';
import {MatDialogRef} from '@angular/material/dialog';
import {SelectConfig} from '@ft/core';
import {TranslateService} from '@ngx-translate/core';
import {AVAILABLE_MODALITIES_CONFIG} from '../../models/consts';

@Component({
    selector: 'ftp-dicomizer',
    templateUrl: './dicomizer.dialog.html',
    styleUrls: [
        './dicomizer.dialog.scss'
    ]
})
export class DicomizerDialog {
    public data: any = {};
    public files: File[] = [];
    public loader: Subscription;
    public genderSelect: SelectConfig = assign({}, PATIENT_GENDER_SELECT_CONFIG, {
        optionContent: item => this._translateService.instant(item.label),
    });

    public modalitiesSelect: SelectConfig = assign({}, AVAILABLE_MODALITIES_CONFIG, {
        valueKey: 'key', optionContent: item => this._translateService.instant(item.label)
    });

    // style related
    public hasBaseDropZoneOver = false;

    constructor(
        private _translateService: TranslateService,
        private _uploadHandler: UploadHandlerService,
        private _dialogRef: MatDialogRef<DicomizerDialog>
    ) {
    }

    public submit() {
        this.loader = this._uploadHandler.dicomizeFiles(this.data, this.files)
            .subscribe(() => this._dialogRef.close(true));
    }
}
