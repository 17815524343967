import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, of, Subscription, timer} from 'rxjs';
import {mergeMap, skip} from 'rxjs/operators';


import {concat, without} from 'lodash';
import {AuthService, GeneralPurposeService, SCALE_ANIMATION, TableColumnDefinition} from '@ft/core';
import {SettingsService} from '../../services/settings.service';
import {ARCHIVES_COLUMNS} from '../../models/table-columns';
import {ArchiveProgressDialog} from '../../dialogs/archives-progress/archive-progress.dialog';
import {MatDialog} from '@angular/material/dialog';
import {debounce, distinctUntilChanged} from 'rxjs/operators';

@Component({
    selector: 'ftp-archives',
    templateUrl: './archives.component.html',
    animations: [
        SCALE_ANIMATION
    ],
    styleUrls: [
        './archives.component.scss'
    ]
})
export class ArchivesComponent implements OnInit, OnDestroy {
    @ViewChild('searchForm', {static: true}) public searchForm: any;

    public isFocused = false;
    public canSearch = false;

    public query: any = {};
    public currentArchive: any;
    public archives: any[] = [];
    public selectedItems: any[] = [];

    public loader: Subscription;
    public source$: Observable<any[]> = of([]);
    public columns: TableColumnDefinition[] = concat(ARCHIVES_COLUMNS as any, {
        label: '', key: 'actions', type: 'actions', actions: [
            {
                class: 'mat-primary',
                icon: 'mdi-keyboard-return',
                method: item => this.unArchive([item], false),
                acl: {resource: 'archive-task', action: 'unarchive', behavior: 'disabled'}
            },
            {
                class: 'mat-warn',
                icon: 'mdi-delete',
                method: item => this.removeArchive([item], false),
                acl: {resource: 'archive-task', action: 'delete', behavior: 'disabled'}
            }
        ]
    } as any) as any;

    private _subscription: Subscription;

    constructor(
        private _dialog: MatDialog,
        private _settingService: SettingsService,
        private _generalPurpose: GeneralPurposeService,

        _authService: AuthService,
    ) {
        this.canSearch = _authService.aclHandler({resource: 'archive-task', action: 'search'});
    }

    public ngOnInit() {
        this.loader = this._settingService.getTasks()
            .subscribe(archives => {
                this.archives = (archives as any);
                if (this.archives.length > 0) this.selectArchive(archives[0]);
            });

        this._subscription = this.searchForm.valueChanges
            .pipe(
                distinctUntilChanged(), debounce(() => timer(750)), skip(1)
            ).subscribe(() => this.handleChange());
    }

    public ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    public selectArchive(archive) {
        this.currentArchive = archive;
        this.source$ = this._settingService.getArchivedStudies(this.currentArchive.directory, this.query);
    }

    public handleChange() {
        this.source$ = this.currentArchive ? this._settingService.getArchivedStudies(this.currentArchive.directory, this.query) : of([]);
    }

    public unArchive(items, multi) {
        this._generalPurpose.openConfirmDialog('settings.archive.unarchive_confirm', {length: items.length})
            .pipe(
                mergeMap(result => result ? this._runUnArchive(items) : of(false))
            )
            .subscribe(() => this._successCall(items, multi));
    }

    public removeArchive(items, multi) {
        this._generalPurpose.openConfirmDialog('settings.archive.remove_confirm', {length: items.length})
            .pipe(
                mergeMap(result => result ? this._settingService.removeArchivedStudies(items) : of(false))
            )
            .subscribe(() => this._successCall(items, multi));
    }

    private _runUnArchive(items): Observable<any> {
        const dialog = this._dialog.open(ArchiveProgressDialog, {
            minWidth: '500px',
            disableClose: true,
            data: {items},
        });

        return dialog.afterClosed();
    }

    private _successCall(items, multi) {
        if (multi) this.selectedItems = [];
        else this.selectedItems = without(this.selectedItems, items[0]);

        this.selectArchive(this.currentArchive);
    }

}
