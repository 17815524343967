import {
    wsFrLang,
    authFrLang,
    coreFrLang,
    commonFrLang,
    wsEnLang,
    authEnLang,
    coreEnLang,
    commonEnLang
} from '@ft/core';

import {visualizerFrLang, visualizerEnLang} from '@ft/file-manager';

// french
import {lang as explorerFr} from './explorer/i18n/fr';
import {lang as settingsFr} from './settings/i18n/fr';
import {lang as viewerFr} from './viewer/i18n/fr';

// english
import {lang as viewerEn} from './viewer/i18n/en';
import {lang as explorerEn} from './explorer/i18n/en';
import {lang as settingsEn} from './settings/i18n/en';

const fr = Object.assign(
    {},
    {ws: wsFrLang},
    {auth: authFrLang},
    {core: coreFrLang},
    {common: commonFrLang},
    {explorer: explorerFr},
    {settings: settingsFr},
    {viewer: Object.assign({}, viewerFr, visualizerFrLang)},
    {
        shared: {
            dcm_upload: 'Upload',
            archives: 'Archives',
            explorer: 'Explorateur',
            copyright: 'Licence accordée à <strong class=\'client\'>{{client}}</strong> jusqu\'a <strong class=\'validity\'>{{validUntil}}</strong></span> - <span>2016-{{currentYear}} &copy; - dépôt légal N°2017LB0009'
        }
    },
);


const en = Object.assign(
    {},
    {ws: wsEnLang},
    {auth: authEnLang},
    {core: coreEnLang},
    {common: commonEnLang},
    {explorer: explorerEn},
    {settings: settingsEn},
    {viewer: Object.assign({}, viewerEn, visualizerEnLang)},
    {
        shared: {
            dcm_upload: 'Upload',
            archives: 'Archives',
            explorer: 'Explorer',
            copyright: 'License granted to <strong class=\'client\'>{{client}}</strong> until <strong class=\'validity\'>{{validUntil}}</strong></span> - <span>2013-{{currentYear}} &copy; - Legal Deposit N°2017LB0009',
        }
    },
);

export const lang = {
    fr, en
};
