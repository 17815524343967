import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {of, Subscription} from 'rxjs';

import * as cornerstone from 'cornerstone-core';
import * as cornerstoneTools from 'cornerstone-tools';

import {chain, find, get, times, without} from 'lodash';

import {getResizeObserver, PrintingService} from '@ft/core';
import {DOWN_UP, FtFileManagerPreviewOverlayRef, SLIDE_CONTENT} from '@ft/file-manager';

import {PrintOption} from '../../classes/print-option';
import {PrintConfig} from '../../models/print-config';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {SeriesItem} from '../../classes/series-item';
import {DCM_PRINT_DIALOG_DATA, PrintService} from '../../services/print.service';
import {ViewerContainerComponent} from '../viewer-container/viewer-container.component';
import {ViewerService} from '../../services/viewer.service';
import {dataURItoBlob, generatePrintOverlayMetadata} from '../../utils/helpers';
import {IMAGE_TYPE} from '../../utils/const';
import {PrintOverlay} from '../../models/overlay-conf';
import {ViewportConf} from '../../models/viewport-conf';
import {delay, mergeMap} from 'rxjs/operators';


@Component({
    selector: 'ftp-print-container',
    templateUrl: './print-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        './print-container.component.scss'
    ],
    animations: [
        SLIDE_CONTENT, DOWN_UP
    ]
})
export class PrintContainerComponent extends ViewerContainerComponent implements OnInit, OnDestroy {
    public isOpen = true;
    public isPrinting = false;
    public animationState = 'enter';
    public animationToolbar = 'enter';
    public animationStateChanged: EventEmitter<AnimationEvent> = new EventEmitter<AnimationEvent>();

    public overlay = false;
    public scaleOverlay = false;
    public loader: Subscription;
    public series: SeriesItem[] = [];

    public paperStyle: any = {};
    public background = 'black';
    public overlayImageId: string;
    public printOptions: PrintOption = new PrintOption();
    public papers: { viewports: ViewportConf[] }[] = [{viewports: []}];
    @ViewChild('containerRef', {read: ElementRef, static: true}) public containerRef: ElementRef;

    private _studyID: string;
    private readonly _errorLabel: string;
    private _resizeSubscription: Subscription;

    constructor(
        private _snackBar: MatSnackBar,
        private _printService: PrintService,
        private _printingService: PrintingService,
        private _dialogRef: FtFileManagerPreviewOverlayRef,
        protected _cdf: ChangeDetectorRef,
        _viewerService: ViewerService,
        translateService: TranslateService,
        @Inject(DCM_PRINT_DIALOG_DATA) _config: PrintConfig) {

        super(_viewerService, _cdf);

        this._studyID = _config.study;
        this._errorLabel = translateService.instant('viewer.print.error_message');

        this.handleLayout(this.printOptions.layout);
    }

    public ngOnInit() {
        this.loader = this._printService.loadStudy(this._studyID)
            .subscribe(series => this.series = series);

        this._resizeSubscription = getResizeObserver(this.containerRef.nativeElement, 50)
            .subscribe(() => {
                this.paperStyle = this._calculatePageDimensions();
                this._cdf.detectChanges();
            });
    }

    // dialog related
    public onAnimationStart(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    public onAnimationDone(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
        this.paperStyle = this._calculatePageDimensions();
    }

    public close() {
        this._dialogRef.close();
    }

    public startExitAnimation() {
        this.isOpen = false;
        this.animationState = 'leave';
        this.animationToolbar = 'leave';
    }

    // overridden methods
    public get activeSeries() {
        return chain(this.papers).map('viewports').flatten().filter('series').map(item => item.series.id).value();
    }

    public getViewportStyle(x: number, y: number) {
        return {height: `${100 / x}%`, width: `${100 / y}%`};
    }

    public handleCompleted(series) {
        this.overlayImageId = series.getThumbnailSrc();
        if (this.papers[0].viewports.length > 1 || this.papers[0].viewports[0].series) {
            return;
        } else {
            this.papers[0].viewports[0].series = series;
        }

        this._cdf.detectChanges();
    }

    public setSeries(id: string, series: string): void {
        const indexes = id.split('-');
        const seriesItem: SeriesItem = find(this.series, {id: series});
        const viewportConf: ViewportConf = get(this.papers, `${indexes[3]}.viewports.${indexes[4]}`);

        if (viewportConf && seriesItem) {
            viewportConf.series = seriesItem;
            this.activeViewport = viewportConf;
        }

        this._cdf.markForCheck();
    }

    public handleLayout(layout) {
        const {x, y} = this.getViewportsCount(layout);
        const numOfViewports = x * y;
        const style = this.getViewportStyle(x, y);

        this.papers.forEach((paper, index) => {
            paper.viewports = times(numOfViewports, i => this.generateViewports(paper.viewports, style, i, index));
        });

        Promise.resolve(null).then(() => this.activeViewport = this.papers[0].viewports[0]);
    }

    public generateViewports(viewports, style, i, index?): ViewportConf {
        const series = get(viewports as any, `${i}.series`, null);
        return {id: `ft-viewer-container-${index}-${i}`, style, activeToolName: this.activeToolName, series} as ViewportConf;
    }

    //
    public handleOptionChange(options: PrintOption) {
        this.printOptions = options;

        Promise.resolve(null).then(() => {
            this.handleLayout(options.layout);

            this.background = options.background;
            this.paperStyle = this._calculatePageDimensions();
        });
    }

    public addPaper() {
        const {x, y} = this.getViewportsCount(this.printOptions.layout);
        const numOfViewports = x * y;
        const style = this.getViewportStyle(x, y);

        this.papers.push({
            viewports: times(numOfViewports, i => this.generateViewports([], style, i, this.papers.length))
        });
    }

    public removePaper(paper) {
        this.papers = without(this.papers, paper);
    }

    // print related
    public runPrint() {
        this.isPrinting = true;

        this.loader = of([])
            .pipe(
                delay(500),
                mergeMap(() => {
                    const images: File[] = this._generateImages();
                    const overlays: PrintOverlay = this.overlay ? {} : generatePrintOverlayMetadata(this.overlayImageId);
                    return this._printService.runPrinting(this.printOptions, images, JSON.stringify(overlays), this.papers.length);
                })
            )
            .subscribe(data => {
                this.isPrinting = false;
                this._cdf.detectChanges();

                if (data) {
                    this.close();
                } else {
                    this._showError();
                }
            });
    }

    public runLocalPrint() {
        this.isPrinting = true;

        this.loader = of([])
            .pipe(
                delay(1500),
                mergeMap(() => {
                    const images: File[] = this._generateImages();
                    const overlays: PrintOverlay = this.overlay ? {} : generatePrintOverlayMetadata(this.overlayImageId);
                    return this._printService.runLocalPrint(this.printOptions, images, JSON.stringify(overlays), this.papers.length);
                })
            )
            .subscribe(blob => {
                this._printingService.printBlob(blob);
                this.isPrinting = false;

                this._cdf.detectChanges();
            });
    }

    // private api
    private _calculatePageDimensions() {
        if (!this.printOptions.FilmSizeID) return {};

        const filmWidth = this.printOptions.FilmSizeID.width;
        const filmHeight = this.printOptions.FilmSizeID.height;
        const maxWidth = this.containerRef.nativeElement.clientWidth - 24;
        const maxHeight = this.containerRef.nativeElement.clientHeight - 24;

        const ratio = Math.min(1,
            this.printOptions.isLandScape ? maxWidth / filmWidth : maxWidth / filmHeight,
            this.printOptions.isLandScape ? maxHeight / filmHeight : maxHeight / filmWidth,
        );

        const width = Math.floor(filmWidth * ratio);
        const height = Math.floor(filmHeight * ratio);

        // only printing where the issue happens
        const reference = Math.min(width, height);
        cornerstoneTools.textStyle.setFontSize(reference * (14 / 900));
        cornerstoneTools.textStyle.setFont(`bold ${reference * (14 / 900)}px Arial, sans-serif`);
        cornerstoneTools.toolStyle.setToolWidth(1.5 * (reference / 512));

        return {
            'width.px': this.printOptions.isLandScape ? width : height,
            'height.px': this.printOptions.isLandScape ? height : width
        };
    }

    private _generateImages(): File[] {
        const images: File[] = [];

        this.viewportsRef.nativeElement.querySelectorAll('.viewport-wrapper .viewport').forEach((element, index) => {
            try {
                const el = cornerstone.getEnabledElement(element);
                const imageBlob = dataURItoBlob(el.canvas.toDataURL(IMAGE_TYPE, 1));
                const imageFile = new File([imageBlob], `${index}.jpg`, {type: IMAGE_TYPE});

                images.push(imageFile);
            } catch (e) {
                images.push(null);
            }
        });

        return images;
    }

    private _showError() {
        this._snackBar.open(this._errorLabel, '', {
            duration: 2500,
            panelClass: 'ft-snackbar',
            verticalPosition: 'bottom',
            horizontalPosition: 'left'
        });
    }
}
