export const lang = {
    loading: 'Loading... {{percentage}}%',
    please_select_series: 'Drag series here to see them .',

    arrow_annotation: {
        dialog_title: 'Title of the dialog',
        dialog_placeholder: 'Annotation'
    },

    download_instance: {
        dialog_title: 'Download',
        dialog_placeholder: 'Title'
    },

    tools: {
        stack_scroll: 'Scroll',
        zoom: 'Zoom',
        contrast: 'Contrast',
        pan: 'Pan',
        magnify: 'Magnify',
        length_measurement: 'Measurement length',
        arrow_annotate: 'Annotate arrow',
        angle_measurement: 'Angle measurement',
        elliptical_roi: 'Elliptical ROI',
        rectangle_roi: 'Rectangle ROI',
        pixel_probe: 'Pixel probe',
        freehand: 'Freehand',
        highlight: 'Highlight',
        crosshairs: 'Crosshairs',
        roi_window: 'Contrast ROI',
        eraser: 'Eraser',
        reset: 'Reset',
        stop: 'Stop',
        play: 'Play',
        fps: 'Images per second',
        invert: 'Invert',
        rotate_left: 'Rotate to left',
        rotate_right: 'Rotate to right',
        h_flip: 'Horizontal flip',
        v_flip: 'Vertical flip',
        download: 'Download',

        layouts: 'Layouts',
        clear_tools: 'Delete annotation',
        viewport_overlay: 'Text overlay',
        viewport_scale_overlay: 'Scale overlay',
        viewports_reference_lines: 'Reference lines',
        synchronize_contrast: 'Synchronize contrast',
        synchronize_pan_zoom: 'Synchronize Pan/Zoom',
    },

    series: {
      loading: 'Loading...'
    },

    print: {
        // buttons
        launch: 'Print',
        cancel: 'Cancel',

        // series instances selection
        series_handle: 'Image selection',
        image_count: 'Image count',
        selection_method: 'Selection method',
        apply: 'Apply',
        append: 'Append',
        incremental: 'Incremental',
        slices: 'Slices',
        manual: 'Manual',

        // blocks
        printer_block: 'Printer',
        printer_options_block: 'Printer options',
        film_options_block: 'Film options',

        // options
        film_type: 'Film Type',
        priority: 'Priority',
        destination: 'Destination (film)',
        copies: 'Nbr of Copies',
        color_print: 'Color printing',

        // film options
        paper_orientation: 'Orientation (film)',
        film_size: 'Film size',
        print_display_mode: 'Display format',
        print_interpolation: 'Interpolation',
        smooth: 'Smooth type',
        border: 'Borders',
        emptyImageDensity: 'Empty image density',
        dpi: 'DPI',

        error_message: 'Printing job failed !!'
    }

};
