import {Injectable, InjectionToken} from '@angular/core';
import {assign} from 'lodash';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {PROMPT_DIALOG} from '@ft/core';

import {ViewerService} from './viewer.service';
import {PrintConfig} from '../models/print-config';
import {PrintOption} from '../classes/print-option';
import {SeriesItem} from '../classes/series-item';
import {SeriesPrintDialog} from '../dialogs/series-print/series-print.dialog';


export const DCM_PRINT_DIALOG_DATA = new InjectionToken<PrintConfig>('DCM_PRINT_DIALOG_DATA');


@Injectable()
export class PrintService {
    constructor(
        private _http: HttpClient,
        private _dialog: MatDialog,
        private _service: ViewerService,
    ) {
    }

    public loadStudy(study): Observable<any> {
        return this._service.getStudyByID(study)
            .pipe(
                mergeMap(data => this._service.mapStudy(data))
            );
    }

    public runPrinting(options: PrintOption, images: File[], overlays, pageCount) {
        const data = this._getPrintFormData(options, images, overlays, pageCount);
        return this._http.post(`/api/printing/printer/${options.Printer.id}/print/`, data);
    }

    public runLocalPrint(options: PrintOption, images: File[], overlays, pageCount) {
        const data = this._getPrintFormData(options, images, overlays, pageCount);
        return this._http.post(`/api/printing/printer/generate-image/`, data, {responseType: 'blob'});
    }

    public seriesPrintDialog(series: SeriesItem) {
        const dialogRef = this._dialog.open(SeriesPrintDialog, assign(PROMPT_DIALOG, {
            data: {series},
            disableClose: true,
        }));

        return dialogRef.afterClosed();
    }

    private _getPrintFormData(options: PrintOption, images: File[], overlays, pageCount) {
        const data = new FormData();

        data.append('pages', pageCount);
        data.append('overlays', overlays);
        data.append('request', options.request());
        data.append('dimension', options.dimension());
        images.forEach((file, index) => data.append(`file[${index}]`, file));

        return data;
    }
}
