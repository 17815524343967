import {Injectable, NgZone} from '@angular/core';
import {GeneralPurposeService} from '@ft/core';
import * as cornerstoneTools from 'cornerstone-tools';
import {ToolConf} from '../models/tool-conf';
import {ViewportOverlaysTool} from '../classes/viewport-overlays';

@Injectable()
export class ToolsService {
    public synchronizer = new cornerstoneTools.Synchronizer('cornerstonenewimage', cornerstoneTools.updateImageSynchronizer);
    public wwwcSynchronizer = new cornerstoneTools.Synchronizer('cornerstoneimagerendered', cornerstoneTools.wwwcSynchronizer);
    public panZoomSynchronizer = new cornerstoneTools.Synchronizer('cornerstoneimagerendered', cornerstoneTools.panZoomSynchronizer);

    public availableTools: ToolConf[] = [
        // Mouse
        {
            name: 'StackScroll',
            class: cornerstoneTools.StackScrollTool
        },
        {
            name: 'Zoom',
            mode: 'active',
            class: cornerstoneTools.ZoomTool,
            options: {mouseButtonMask: 2},
        },
        {
            name: 'Wwwc',
            mode: 'active',
            class: cornerstoneTools.WwwcTool,
            synchronizer: this.wwwcSynchronizer,
            options: {mouseButtonMask: 1},
        },
        {
            name: 'WwwcRegion',
            class: cornerstoneTools.WwwcRegionTool,
        },
        {
            name: 'Pan',
            mode: 'active',
            class: cornerstoneTools.PanTool,
            options: {mouseButtonMask: 4},
        },
        {name: 'Magnify', class: cornerstoneTools.MagnifyTool},

        // annotations
        {name: 'Length', class: cornerstoneTools.LengthTool, mode: 'passive'},
        {
            name: 'ArrowAnnotate',
            class: cornerstoneTools.ArrowAnnotateTool, mode: 'passive',
            configuration: {
                getTextCallback: (callback) => this._getTextCallback(callback),
                changeTextCallback: (data, ev, callback) => this._changeTextCallback(data, callback)
            }
        },
        {name: 'Angle', class: cornerstoneTools.AngleTool, mode: 'passive'},
        {name: 'Bidirectional', class: cornerstoneTools.BidirectionalTool, mode: 'passive'},
        {name: 'EllipticalRoi', class: cornerstoneTools.EllipticalRoiTool, mode: 'passive'},
        {name: 'RectangleRoi', class: cornerstoneTools.RectangleRoiTool, mode: 'passive'},
        {name: 'DragProbe', class: cornerstoneTools.DragProbeTool, mode: 'passive'},
        {name: 'Eraser', class: cornerstoneTools.EraserTool, mode: 'passive'},

        // references
        {
            name: 'Crosshairs', class: cornerstoneTools.CrosshairsTool, mode: 'passive', synchronizer: this.synchronizer
        },
        // Scroll
        {name: 'StackScrollMouseWheel', class: cornerstoneTools.StackScrollMouseWheelTool, mode: 'active'},

        // Touch
        {name: 'PanMultiTouch', class: cornerstoneTools.PanMultiTouchTool, mode: 'active'},
        {name: 'ZoomTouchPinch', class: cornerstoneTools.ZoomTouchPinchTool, mode: 'active'},
        {name: 'StackScrollMultiTouch', class: cornerstoneTools.StackScrollMultiTouchTool, mode: 'active'},

        // overlay
        {name: 'Overlay', class: cornerstoneTools.OverlayTool, mode: 'enabled'},
    ];


    // better idea
    public textOverlay: ToolConf = {name: 'ViewportOverlays', class: ViewportOverlaysTool};
    public scaleOverlay: ToolConf = {name: 'ScaleOverlay', class: cornerstoneTools.ScaleOverlayTool};
    public referenceLines: ToolConf = {
        name: 'ReferenceLines', class: cornerstoneTools.ReferenceLinesTool, options: {synchronizationContext: this.synchronizer}
    };

    constructor(
        private _zone: NgZone,
        private _generalPurposeService: GeneralPurposeService
    ) {
        this.wwwcSynchronizer.enabled = false;
        this.panZoomSynchronizer.enabled = false;

        this.availableTools.push(this.textOverlay);
        this.availableTools.push(this.scaleOverlay);
        this.availableTools.push(this.referenceLines);
    }

    private _getTextCallback(callback) {
        this._zone.runTask(() => {
            this._generalPurposeService.openPromptDialog('viewer.arrow_annotation.dialog_title', 'viewer.arrow_annotation.dialog_placeholder')
                .subscribe(title => callback(title), () => callback(null, undefined, true));
        });
    }

    private _changeTextCallback(data, callback) {
        this._zone.runTask(() => {
            this._generalPurposeService.openPromptDialog(
                'viewer.arrow_annotation.dialog_title',
                'viewer.arrow_annotation.dialog_placeholder',
                null, null, data.text
            ).subscribe(title => callback(title, data), () => callback(data, undefined, true));
        });
    }
}
