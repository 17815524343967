import {concat} from 'lodash';
import {FtOverlayConfig} from '@ft/file-manager';

export interface PrintConfig extends FtOverlayConfig {
    study: string;
}

// print const
export const BASE_SELECT_CONF = {
    key: 'value', compareKey: 'value'
};

export const FILM_TYPES = [
    {value: 'BLUE FILM'},
    {value: 'CLEAR FILM'},
    {value: 'MAMMO BLUE FILM'},
    {value: 'MAMMO CLEAR FILM'},
    {value: 'PAPER'}
];

export const PRINT_PRIORITY = [
    {value: 'LOW'},
    {value: 'MED'},
    {value: 'HIGH'},
];

export const PRINT_DESTINATION = [
    {value: 'MAGAZINE'},
    {value: 'PROCESSOR'}
];

export const PAPER_ORIENTATION = [
    {value: 'PORTRAIT', isLandScape: false},
    {value: 'LANDSCAPE', isLandScape: true},
];

export const DICOM_FILE_SIZE = [
    {value: '8INX10IN'},
    {value: '8_5INX11IN'},
    {value: '10INX12IN'},
    {value: '10INX14IN'},
    {value: '11INX14IN'},
    {value: '11INX17IN'},
    {value: '14INX14IN'},
    {value: '14INX17IN'},
    {value: '24CMX24CM'},
    {value: '24CMX30CM'},
];

export const FILM_SIZE = concat(DICOM_FILE_SIZE, [
    {value: 'A4'},
    {value: 'A3'},
]);

export const PRINT_DISPLAY_MODE = [
    {value: 'STANDARD\\1,1', layout: '1x1'},
    {value: 'STANDARD\\1,2', layout: '1x2'},
    {value: 'STANDARD\\1,3', layout: '1x3'},
    {value: 'STANDARD\\2,1', layout: '2x1'},
    {value: 'STANDARD\\2,2', layout: '2x2'},
    {value: 'STANDARD\\2,3', layout: '2x3'},
    {value: 'STANDARD\\2,4', layout: '2x4'},
    {value: 'STANDARD\\3,3', layout: '3x3'},
    {value: 'STANDARD\\3,4', layout: '3x4'},
    {value: 'STANDARD\\4,4', layout: '4x4'},
    {value: 'STANDARD\\5,4', layout: '5x4'},
    {value: 'STANDARD\\5,5', layout: '5x5'},
    {value: 'STANDARD\\5,6', layout: '5x6'},
];

export const PRINT_INTERPOLATION = [
    {value: 'REPLICATE'},
    {value: 'BILINEAR'},
    {value: 'CUBIC'},
];

export const SMOOTH = [
    {value: 'MEDIUM'},
    {value: 'SHARP'},
    {value: 'SMOOTH'},
];

export const BORDER = [
    {value: 'YES'},
    {value: 'NO'},
];

export const EMPTY_IMAGE_DENSITY = [
    {value: 'BLACK'},
    {value: 'WHITE'}
];

export const DPI = [
    {value: '100'},
    {value: '150'},
    {value: '200'},
    {value: '250'},
    {value: '300'},
];

// print selection methods
export const INCREMENTAL_SELECTION = 'INCREMENTAL';
export const SLICES_SELECTION = 'SLICES';
export const MANUAL_SELECTION = 'MANUAL';

export const SERIES_IMAGE_SELECTION_METHODS = [
    {label: 'viewer.print.incremental', value: INCREMENTAL_SELECTION},
    {label: 'viewer.print.slices', value: SLICES_SELECTION},
    {label: 'viewer.print.manual', value: MANUAL_SELECTION},
];

export interface SeriesPrintRule {
    count?: number;
    method?: any;
}
